<template>
    <div class="medialist">
         <a-page-header
            :title="$t('inbound.list.titel')"
            class="header_title"
        />
        <a-spin :spinning="spinning">
        <div class="box_content">
              <table>
                <tr>
                  <td>{{$t('inbound.list.search.item_1')}}:</td>
                  <td><a-checkbox :checked="shipment_status_0" @change="(e)=>handleChange(e,'shipment_status','0')">{{$t('inbound.all')}}</a-checkbox></td>
                  <td><a-checkbox :checked="shipment_status_1" @change="(e)=>handleChange(e,'shipment_status','1')">{{$t('inbound.shipment_status_text.pending')}}</a-checkbox></td>
                  <td><a-checkbox :checked="shipment_status_2" @change="(e)=>handleChange(e,'shipment_status','2')">{{$t('inbound.shipment_status_text.delivered')}}</a-checkbox></td>
                  <td></td>
                  <td style="text-align:center;width: 15px;">|</td>
                  <td>{{$t('inbound.list.search.item_2')}}:</td>
                  <td><a-date-picker style="width: 100%" :value="create_date_start" @change="(date, dateString)=>handleChange(dateString,'create_date_start','start')"/></td>
                  <td>~</td>
                  <td><a-date-picker style="width: 100%" :value="create_date_finish" @change="(date, dateString)=>handleChange(dateString,'create_date_finish','finish')"/></td>
                </tr>
                <tr>
                  <td>{{$t('inbound.list.search.item_3')}}:</td>
                  <td><a-checkbox :checked="inventory_status_0" @change="(e)=>handleChange(e,'inventory_status','0')">{{$t('inbound.all')}}</a-checkbox></td>
                  <td><a-checkbox :checked="inventory_status_1" @change="(e)=>handleChange(e,'inventory_status','1')">{{$t('inbound.inventory_status_text.in_stock')}}</a-checkbox></td>
                  <td><a-checkbox :checked="inventory_status_2" @change="(e)=>handleChange(e,'inventory_status','2')">{{$t('inbound.inventory_status_text.in_inspection')}}</a-checkbox></td>
                  <td><a-checkbox :checked="inventory_status_3"  @change="(e)=>handleChange(e,'inventory_status','3')">{{$t('inbound.inventory_status_text.pending_to_receive')}}</a-checkbox></td>
                  <td style="text-align:center;width: 15px;">|</td>
                  <td>{{$t('inbound.list.search.item_4')}}:</td>
                  <td><a-date-picker style="width: 100%" :value="delivered_date_start" @change="(date, dateString)=>handleChange(dateString,'delivered_date_start','0')"/></td>
                  <td>~</td>
                  <td><a-date-picker style="width: 100%" :value="delivered_date_finish" @change="(date, dateString)=>handleChange(dateString,'delivered_date_finish','0')"/></td>
                </tr>
                <tr>
                  <!-- <td>Warehouse:</td>
                  <td colspan="2">
                    <a-select style="width: 100%" default-value="0" :value="Warehouse" @change="(e)=>handleChange(e,'Warehouse','0')">
                        <a-select-option value="0">All</a-select-option>
                        <a-select-option value="1">Gyroor</a-select-option>
                        <a-select-option value="2">PanurgyOEM</a-select-option>
                        <a-select-option value="3">Return Helper</a-select-option>
                        <a-select-option value="4">SZ Warehouse</a-select-option>
                    </a-select>
                  </td> -->
                  <!-- <td style="text-align:center;">|</td> -->
                  <td colspan="3">
                    <a-select style="width: 100%" default-value="1" :value="other_field" @change="(e)=>handleChange(e,'other_field','0')">
                        <a-select-option value="1">{{$t('inbound.list.table.head_titel_2')}}</a-select-option>
                        <a-select-option value="2">{{$t('inbound.list.table.head_titel_3')}}</a-select-option>
                        <a-select-option value="3">{{$t('inbound.list.table.head_titel_4')}}</a-select-option>
                        <a-select-option value="4">{{$t('inbound.list.table.head_titel_7')}}</a-select-option>
                    </a-select>
                  </td>
                  <td><a-input placeholder="" :value="other_value" @change="(e)=>handleChange(e,'other_value','0')"/></td>
                  <td>
                      <a-button class="ant-btn-danger" @click="mKPOrderIDBtn"  type="primary">{{$t('inbound.list.search.title')}}</a-button>
                  </td>
                </tr>
              </table>
              <div style="">&nbsp;</div>
              <a-tabs default-active-key="0" @change="callback" :animated="false" size="large">
                <a-tab-pane key="0" :tab="$t('inbound.list.tab_1')"></a-tab-pane>
                <a-tab-pane key="2" tab="DataHunt"></a-tab-pane>
                <a-tab-pane key="5" tab="Microsel"></a-tab-pane>
                <a-tab-pane key="1" :tab="$t('inbound.list.tab_3')" force-render></a-tab-pane>
                <!-- <a-tab-pane key="1" tab="Gyroor" force-render></a-tab-pane> -->
                  <!-- <a-button @click="addOrderBtn">CREATE MANUAL ORDER</a-button> -->
                <!-- <a-tab-pane key="3" tab="Return Helper"></a-tab-pane> -->
                <!-- <a-tab-pane key="4" tab="SZ Warehouse"></a-tab-pane> -->
              </a-tabs>
              <a-table 
              :dataSource="dataSource" 
              :columns="columns" 
              :pagination="pagination"
               @change="pageChange"
              size="middle"
               >
                <a-tag
                  slot="shipment_status" slot-scope="text"
                  :key="text"
                  :color="'geekblue'"
                >
                  {{$t(shipment_status_text(text))}}
                </a-tag>
                <a-tag
                  slot="inventory_status" slot-scope="text"
                  :key="text"
                  :color="'geekblue'"
                >
                  {{$t(inventory_status_text(text))}}
                </a-tag>
                <a-tag
                  slot="warehouse" slot-scope="text"
                  :key="text"
                  :color="'geekblue'"
                >
                  {{warehouse_text(text)}}
                </a-tag>
                <span slot="delivered_date" slot-scope="text, record">
                    {{format(text)}}
                </span>
                <span slot="freight_status" slot-scope="text, record">
                    {{$t(freight_status_text(text))}}
                </span>
                <span slot="action" slot-scope="text, record">
                    <!-- <a>View</a> -->
                    <!-- <span style="padding: 0 5px;">|</span> -->
                    <a style="color:#ff4d4f;font-size: 18px;"><a-icon type="download" @click="dowload_img(record.purchase_shipment_label_image)"/></a>
                </span>
                </a-table>
        </div>
        </a-spin>
    </div>
</template>

<script>
import {add,getList} from '@/network/inbound';
export default {
  name: 'NodeprojectMedia',
  components: {  },
  directives: {  },
  computed:{
  columns(){
    return [
          {
            title: this.$t('inbound.list.table.head_titel_1'),
            dataIndex: 'create_date',
            key: 'create_date',
          },
          {
            title: this.$t('inbound.list.table.head_titel_2'),
            dataIndex: 'rma',
            key: 'rma',
          },
          {
            title: this.$t('inbound.list.table.head_titel_3'),
            dataIndex: 'warehouse_sn',
            key: 'warehouse_sn',
          },
          {
            title: this.$t('inbound.list.table.head_titel_4'),
            dataIndex: 'user_record_id',
            key: 'user_record_id',
          },
          {
            title: this.$t('inbound.list.table.head_titel_5'),
            dataIndex: 'shipment_status',
            key: 'shipment_status',
            scopedSlots: { customRender: "shipment_status" },
          },
          {
            title: this.$t('inbound.list.table.head_titel_6'),
            dataIndex: 'delivered_date',
            key: 'delivered_date',
            scopedSlots: { customRender: "delivered_date" },
          },
          {
            title: this.$t('inbound.list.table.head_titel_7'),
            dataIndex: 'tracking_number',
            key: 'tracking_number',
          },
          {
            title: this.$t('inbound.list.table.head_titel_8'),
            dataIndex: 'warehouse',
            key: 'warehouse',
            scopedSlots: { customRender: "warehouse" },
          },
          {
            title: this.$t('inbound.list.table.head_titel_9'),
            dataIndex: 'inventory_status',
            key: 'inventory_status',
            scopedSlots: { customRender: "inventory_status" },
          },{
            title: this.$t('inbound.list.table.head_titel_10'),
            dataIndex: 'freight',
            key: 'freight'
          },{
            title: this.$t('inbound.list.table.head_titel_11'),
            dataIndex: 'freight_status',
            key: 'freight_status',
            scopedSlots: { customRender: "freight_status" },
          },{
            title: this.$t('inbound.list.table.head_titel_12'),
            dataIndex: 'reason_for_return',
            key: 'reason_for_return'
          },
          // {
          //   title: 'Action',
          //   dataIndex: 'action',
          //   scopedSlots: { customRender: 'action' },
          // }
        ];
  },
  },
  data() {
    return {
      loading:false,
      spinning:false,

      shipment_status_0:true,
      shipment_status_1:true,
      shipment_status_2:true,

      inventory_status_0:true,
      inventory_status_1:true,
      inventory_status_2:true,
      inventory_status_3:true,

      Warehouse:'0',
      other_field:'',
      other_value:'',
      create_date_start:'',
      create_date_finish:'',
      delivered_date_start:'',
      delivered_date_finish:'',

      pagination: { defaultPageSize: 15, current: 1, total: 0 },
      dataSource: [],
      // columns: 
    };
  },created(){
    this.getList(1);
  },
  mounted() {
    
  },
  methods: {
    callback(key) {
      console.log(key);
      this.Warehouse = key;
      this.parameter = {
        shipment_status_0:this.shipment_status_0,
        shipment_status_1:this.shipment_status_1,
        shipment_status_2:this.shipment_status_2,

        inventory_status_0:this.inventory_status_0,
        inventory_status_1:this.inventory_status_1,
        inventory_status_2:this.inventory_status_2,
        inventory_status_3:this.inventory_status_3,

        Warehouse:key,
        other_field:this.other_field,
        other_value:this.other_value,
        create_date_start:this.create_date_start,
        create_date_finish:this.create_date_finish,
        delivered_date_start:this.delivered_date_start,
        delivered_date_finish:this.delivered_date_finish,
      }
      this.getList(1);
    },
    freight_status_text(e) {
        if(e == '1'){
          return 'inbound.freight_status_text.paid';
        } else 
        if(e == '2'){
          return 'inbound.freight_status_text.unpaid';
        }
    },
    shipment_status_text(e) {
        if(e == '1'){
          return 'inbound.shipment_status_text.pending';
        } else 
        if(e == '2'){
          return 'inbound.shipment_status_text.delivered';
        }
    },
    inventory_status_text(e) {
        if(e == '1'){
          // return 'In-Stock';
          return 'inbound.inventory_status_text.in_stock';
        } else 
        if(e == '2'){
          // return 'In Inspection';
          return 'inbound.inventory_status_text.in_inspection';
        } else 
        if(e == '3'){
          // return 'Pending to Receive';
          return 'inbound.inventory_status_text.pending_to_receive';
        }
    },
    warehouse_text(e) {
        if(e == '1'){
          return 'Gyroor';
        } else 
        if(e == '2'){
          return 'PanurgyOEM';
        } else 
        if(e == '3'){
          return 'Return Helper';
        } else 
        if(e == '4'){
          return 'SZ Warehouse';
        }
    },
    mKPOrderIDBtn(){
      this.parameter = {
        shipment_status_0:this.shipment_status_0,
        shipment_status_1:this.shipment_status_1,
        shipment_status_2:this.shipment_status_2,

        inventory_status_0:this.inventory_status_0,
        inventory_status_1:this.inventory_status_1,
        inventory_status_2:this.inventory_status_2,
        inventory_status_3:this.inventory_status_3,

        Warehouse:this.Warehouse,
        other_field:this.other_field,
        other_value:this.other_value,
        create_date_start:this.create_date_start,
        create_date_finish:this.create_date_finish,
        delivered_date_start:this.delivered_date_start,
        delivered_date_finish:this.delivered_date_finish,
      }
      this.getList(1);
    },
    handleChange(e,k,v){
      console.log(e,k,v);
      if(k == 'shipment_status'){
        if(v == '0'){
          if(this.shipment_status_0){
            this.shipment_status_0 = false;
            this.shipment_status_1 = false;
            this.shipment_status_2 = false;
          }else{
            this.shipment_status_0 = true;
            this.shipment_status_1 = true;
            this.shipment_status_2 = true;
          }
        }
        if(v == '1'){
          if(this.shipment_status_1){
            this.shipment_status_0 = false;
            this.shipment_status_1 = false;
          }else{
            if(this.shipment_status_2){
              this.shipment_status_0 = true;
              this.shipment_status_1 = true;
              this.shipment_status_2 = true;
            }else{
              this.shipment_status_0 = false;
              this.shipment_status_1 = true;
            }
          }
        }
        if(v == '2'){
          if(this.shipment_status_2){
            this.shipment_status_0 = false;
            this.shipment_status_2 = false;
          }else{
            if(this.shipment_status_1){
              this.shipment_status_0 = true;
              this.shipment_status_1 = true;
              this.shipment_status_2 = true;
            }else{
              this.shipment_status_0 = false;
              this.shipment_status_2 = true;
            }
          }
        }
      }
      if(k == 'inventory_status'){
        if(v == '0'){
          if(this.inventory_status_0){
            this.inventory_status_0 = false;
            this.inventory_status_1 = false;
            this.inventory_status_2 = false;
            this.inventory_status_3 = false;
          }else{
            this.inventory_status_0 = true;
            this.inventory_status_1 = true;
            this.inventory_status_2 = true;
            this.inventory_status_3 = true;
          }
        }
        if(v == '1'){
          if(this.inventory_status_1){
            this.inventory_status_0 = false;
            this.inventory_status_1 = false;
          }else{
            if(this.inventory_status_2 && this.inventory_status_3){
              this.inventory_status_0 = true;
              this.inventory_status_1 = true;
              this.inventory_status_2 = true;
              this.inventory_status_3 = true;
            }else{
              this.inventory_status_0 = false;
              this.inventory_status_1 = true;
            }
          }
        }
        if(v == '2'){
          if(this.inventory_status_2){
            this.inventory_status_0 = false;
            this.inventory_status_2 = false;
          }else{
            if(this.inventory_status_1 && this.inventory_status_3){
              this.inventory_status_0 = true;
              this.inventory_status_1 = true;
              this.inventory_status_2 = true;
              this.inventory_status_3 = true;
            }else{
              this.inventory_status_0 = false;
              this.inventory_status_2 = true;
            }
          }
        }
        if(v == '3'){
          if(this.inventory_status_3){
            this.inventory_status_0 = false;
            this.inventory_status_3 = false;
          }else{
            if(this.inventory_status_1 && this.inventory_status_2){
              this.inventory_status_0 = true;
              this.inventory_status_1 = true;
              this.inventory_status_2 = true;
              this.inventory_status_3 = true;
            }else{
              this.inventory_status_0 = false;
              this.inventory_status_3 = true;
            }
          }
        }
      }
      if(k == 'Warehouse'){
        this.Warehouse = e;
      }
      if(k == 'other_field'){
        this.other_field = e;
      }
      if(k == 'other_value'){
        this.other_value = e.target.value;
      }
      if(k == 'create_date_start'){
        this.create_date_start = e;
      }
      if(k == 'create_date_finish'){
        this.create_date_finish = e;
      }
      if(k == 'delivered_date_start'){
        this.delivered_date_start = e;
      }
      if(k == 'delivered_date_finish'){
        this.delivered_date_finish = e;
      }
    },
    pageChange(pagination, filters, sorter) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.getList(pagination.current);
    },
    getList(page){
      this.loading = true;
      getList(page,this.parameter).then((res) => {
      if (res.code == 0) {
        this.$set(this.pagination, "total", res.total);
        this.dataSource = res.data;
        // this.dataSource =
        // page === 1
        //   ? res.data
        //   : this.dataSource.concat(res.data);
        // console.log(this.data)
        // let fornum = Math.ceil(res.total / 10);
        // if (page != fornum && fornum > 0) {
        //   console.log(page != 2);
        //   this.getList(page + 1);
        // }
        this.loading = false;
        return;
      }
    });
    },
    dowload_img(url){
        if(url){
          // window.open('http://makeradmin.net'+url);
          window.open('https://shady.ecomgear.cn/kenneth_testing/maker/admin'+url);
        }else{
          this.$message.error('not data')
        }
    },
    format(shijianchuo){
      if(shijianchuo && shijianchuo!=null){
        //shijianchuo是整数，否则要parseInt转换
        var time = new Date(shijianchuo*1000);
        var y = time.getFullYear();
        var m = time.getMonth()+1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        // return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s);
        return y+'-'+this.add0(m)+'-'+this.add0(d);
      }else{
        return '-';
      }
    },
    add0(m){
      return m<10?'0'+m:m;
    }
  },
};
</script>

<style  scoped>
  table tr td{
    padding: 5px;
  }
</style>